import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/blog.css'
import {Helmet}from"react-helmet";
import b15 from '../images/blg15-min.png';
import b16 from '../images/blg17.png';
import Blog6 from '../images/blog6.png';
import b13 from '../images/bg13-min.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Blog =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Comprehensive Legal Support Services | Verilex Solutions</title>
      <meta name="description" content="Get expert legal support with VeriLex Solutions. Our comprehensive services cover all your legal needs with precision and efficiency. Discover more today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section style={{height:"70vh"}} className='header_top' >
      <div className='container_top'>
     <h1>Blog </h1>
       </div>
    
    </section>
    <br /><br /><br />
      <section className='blog_sec'>
      <div className='blog_row'>

      <div  className='blog_col'>
        
        <a href="Bitcoin-Breaks-$100K"><img src={b16} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Bitcoin-Breaks-$100K"> Bitcoin Breaks $100K: A Seismic Shift in the Crypto Market </a></h2>

        </div>

      <div  className='blog_col'>
        
        <a href="Understanding-Legal-Document"><img src={b15} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="Understanding-Legal-Document">Understanding Legal Document Review – Why It Matters</a></h2>

        </div>
 

        <div  style={{visibility:'hidden'}} className='blog_col'>
        
        <a href="blog2"><img src={b15} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="blog2">Understanding Legal Document Review – Why It Matters</a></h2>

        </div>
 
 
        <div  style={{visibility:'hidden'}} className='blog_col'>
        
        <a href="blog2"><img src={b15} alt="blog1_pic" /></a>
          <h4>Business & Finance</h4>
          <h2><a href="blog2">Understanding Legal Document Review – Why It Matters</a></h2>

        </div>
 
   
 
       
       
      </div>
     
    
      </section>
    </div>
  )
}
export default Blog;