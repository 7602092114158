import React, { useEffect, useRef} from 'react'
import { NavLink } from 'react-router-dom'
import './header.css'
import logo from './logo.png';




 const Header =()=> {
  const headerRef = useRef(null);
  const stickyHeaderFunc = ()=>{
    window.addEventListener('scroll', () => {
      if(
        document.body.scrollTop > 80  ||
        document.documentElement.scrollTop > 80)
        {
        headerRef.current.classList.add('sticky__header')
      }
      else{
        headerRef.current.classList.remove('sticky__header')
      }
    })
  }



  useEffect(()=>{

// sidebar open close js code
let navLinks = document.querySelector(".nav-links");
let menuOpenBtn = document.querySelector(".navbar .bx-menu");
let menuCloseBtn = document.querySelector(".nav-links .bx-x");


menuOpenBtn.onclick = function() {
navLinks.style.left = "0";

}

menuCloseBtn.onclick = function() {
  navLinks.style.left = "-100%";
}

document.onclick=function(e){
  if( !navLinks.contains(e.target) && !menuOpenBtn.contains(e.target)){
    navLinks.style.left = "-100%";
      
  }
 }




// sidebar submenu open close js code

let jsArrow = document.querySelector(".js-arrow");
jsArrow.onclick = function() {
 navLinks.classList.toggle("show3");
}




stickyHeaderFunc();
return () => window.removeEventListener("scroll", stickyHeaderFunc); 
   });


  return (
    <div >
  <nav ref={headerRef}>
    <div class="navbar">
      <i class='bx bx-menu'></i>
      <div class="logo" ><NavLink to="/"><img    src={logo}/></NavLink></div>
      <div class="nav-links" >
        <div class="sidebar-logo">
          <span class="logo-name">VeriLex</span>
          <i class='bx bx-x' style={{marginLeft:"70%",marginTop:"20%"}}></i>
        </div>
        <ul class="links">
          <li><NavLink to="/">Home</NavLink></li>

         
          <li style={{display:"none"}}>
            <NavLink to="/services" className='js-arrow'>Services</NavLink>
            <i class='bx bxs-chevron-down js-arrow arrow ' ></i>
            <ul class="js-sub-menu sub-menu">
            <li><NavLink to="/general-construction">General Construction</NavLink></li>
            <li><NavLink to="/structural">Structural Engineering</NavLink></li>
            <li><NavLink to="/interior-remodeling">Interior Remodeling</NavLink></li>
            
            </ul>
          </li>
          <li><NavLink to="about">About Us</NavLink></li>
          <li><NavLink to="services">Services</NavLink></li>
          <li><NavLink to="casestudies">Case Studies</NavLink></li>
          <li><NavLink to="blog">Blog</NavLink></li>
          <li><NavLink to="contact">Contact Us</NavLink></li>
          
        </ul>
      </div>
          </div>
  </nav>
 

    </div>
  )
}
export default Header;

